import {
  colors,
  mediaQueries,
} from "@netmedi/frontend-design-system/dist/styles/variables";
import rem, {
  marginLeft,
} from "@netmedi/frontend-design-system/dist/styles/styleHelpers";
import styled from "styled-components";
import rtl from "styled-components-rtl";
import { InitialsIcon } from "../Profile/InitialsIcon.styles";
import { mediaMax } from "@netmedi/frontend-design-system/dist/styles/mixins/mediaQueryMixins";
import DiscoButton from "shared/components/LoginPage/DiscoButton";

export const SymanticTR = styled.label`
  display: table-row;
`;
export const SymanticTD = styled.span`
  display: table-cell;
`;

export const SymanticTHead = styled.div`
  display: table-header-group;
`;
export const SymanticTBody = styled.div`
  display: table-row-group;

  ${SymanticTD} {
    ${rtl`text-align: left;`}
    color: ${colors.black};

    &:hover {
      color: ${colors.blue700};
    }
  }
`;
export const SymanticCaption = styled.div`
  display: table-caption;
  caption-side: bottom;
`;

export const SymanticTDNameColumn = styled(SymanticTD)`
  ${rtl`direction: ltr;`}

  span {
    display: inline-flex;
  }
`;

export const Selector = styled.div`
  display: block;
  border-collapse: collapse;
  margin: 0 auto;
  padding: 0;
  font-family: "Graphik", "Helvetica Neue", Helvetica, Arial, sans-serif;

  ${SymanticTHead} a {
    color: ${colors.black};
    display: block;
    height: max-content;
    width: max-content;
    ${rtl`margin-left: 0.85rem;`}
    border-radius: 100%;
    outline-offset: 1px;

    &:hover,
    &:focus {
      outline: 2px solid ${colors.blue500};
    }
  }

  ${SymanticTHead} span:first-child {
    text-align: center;
  }

  ${SymanticTBody},
  ${SymanticCaption} {
    label {
      display: table-row;
      cursor: pointer;
      min-width: fit-content;

      &:focus {
        outline: none;
      }
    }
  }

  ${SymanticTBody},
  ${SymanticTHead} {
    ${SymanticTD} {
      border-bottom: 1px solid ${colors.gray500};

      div,
      h4 {
        ${rtl`text-align: left;`}
      }

      // temporary fix until HCP navbar is there
      a[href="/signout"] {
        ${rtl`margin-left: 0.85rem;`}

        svg {
          width: 1.5rem;
        }
      }
    }
  }

  ${SymanticTBody} {
    label {
      font-size: ${rem(18)};
      &[tabindex] > span {
        padding: 1.5rem 0.25rem;
        margin: 0;
      }

      color: ${colors.blue700};

      &:hover,
      &:focus {
        color: ${colors.black};
      }

      &:hover ${InitialsIcon} {
        outline: 1px solid ${colors.black};
      }

      &:focus ${InitialsIcon} {
        outline: 2px solid ${colors.blue500};
      }

      input[type="radio"] {
        display: none;
      }

      ${SymanticTD} {
        line-height: 150%;
      }
    }
  }

  label ${InitialsIcon} {
    ${rtl`margin-right: 1rem;`}
  }

  .chevron {
    position: relative;
    height: max-content;
    ${rtl`direction: ltr;`}

    svg {
      width: 3rem;
      ${rtl`padding-left: 1.5rem;`}
    }
  }
`;

export const ProfileList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; // This ensures the div takes up the full height

  h3 {
    letter-spacing: normal;
    text-align: center;
    font-weight: 500;
    font-size: ${rem(18)};
  }
`;

export const ProfileSelectorSidebarWrapper = styled.div`
  & > span {
    margin: 1.25rem;
  }
`;

export const CenterBlock = styled.div`
  position: absolute;
  ${rtl`transform: translate(-50%, -50%);`}
  top: 50%;
  ${rtl`left: 50%;`}
  margin: 0;

  ${mediaMax(mediaQueries.medium)} {
    width: 100%;
  }
`;

export const CheckBoxWrapper = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const QRCodeButton = styled(DiscoButton)`
  ${marginLeft("xs")}
`;
