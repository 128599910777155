import React, { useEffect, useState } from "react";
import { Button, Icon } from "@netmedi/frontend-design-system";
import { FormattedMessage } from "react-intl";
import Logo from "common/components/Logo";
import { Modal, ModalLogo, ModalNav } from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";
import QRCode from "qrcode.react";
import { endpoints, get, post } from "common/utils/api";
import Loader from "./Loader";
import { QRCodeContainer } from "./LinkMobileDeviceModal.styles";

export type LinkMobileDeviceModalProps = TitleAndBody & {
  type: "link_mobile_device_modal";
  /** Text to be shown on the dismiss-button */
  dismissText?: string;
  /** Whether to show the Kaiku Health -logo on top of the modal */
  logo?: boolean;
  className?: string;
};

/** Alert modal shows the user an alert with a dismiss-button. */
const LinkMobileDeviceModal = ({
  dismiss,
  dismissText,
  title,
  logo,
  className,
}: LinkMobileDeviceModalProps & Dismiss) => {
  const [link, setLink] = useState<string | null>(null);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    // Get login link
    post<{ url: string }>(endpoints.mobileLogin.generate).then(({ url }) => {
      setLink(url);
    });
  }, []);

  useEffect(() => {
    if (!link) return;

    const token = link.split("/").pop();

    if (!token) return;

    const interval = setInterval(() => {
      get<{ expired: boolean }>(endpoints.mobileLogin.check(token)).then(
        ({ expired }) => {
          if (expired) {
            clearInterval(interval);
            setExpired(true);
            setTimeout(() => {
              dismiss();
            }, 4000);
          }
        },
      );
    }, 1000);

    return () => clearInterval(interval);
  }, [link]);

  return (
    <Modal className={className} onClick={(e: any) => e.stopPropagation()}>
      {logo && (
        <ModalLogo>
          <Logo />
        </ModalLogo>
      )}
      {title && <h3>{title}</h3>}
      {!link && <Loader />}
      {link && !expired && (
        <QRCodeContainer>
          <div style={{ margin: "1rem", textAlign: "center" }}>
            Scan the QR code with your mobile device to log in. If you don't
            have a Kaiku Mobile installed you will get redirected to the app
            store or google play store.
          </div>
          {link && <QRCode value={link} size={400} />}
          {<div style={{ fontSize: "8pt" }}>{link}</div>}
        </QRCodeContainer>
      )}
      {expired && (
        <div style={{ margin: "1rem", textAlign: "center" }}>
          <Icon name="ok_circle_48px" size="xxl" />
        </div>
      )}
      <ModalNav>
        <Button size="small" onClick={dismiss}>
          <FormattedMessage id={dismissText || "modal.dismiss"} />
        </Button>
      </ModalNav>
    </Modal>
  );
};

export default LinkMobileDeviceModal;
