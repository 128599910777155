import React, { useState } from "react";
import { DiscoButtonStyled } from "./GlobalLoginForm.styles";
import { IconName } from "@netmedi/frontend-design-system/dist/components/Icon";

export interface DiscoButtonProps {}

// Should be exported from Button
type ButtonType = "accent" | "primary" | "secondary" | "ghost" | "danger";
type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  type?: ButtonType;
  size?: "small" | "default";
  className?: string;
  disabled?: boolean;
  submit?: boolean;
  primary?: boolean;
  icon?: IconName;
  iconEdge?: "left" | "right";
  loading?: boolean;
  ref?: React.RefObject<HTMLButtonElement>;
};

const DiscoButton: React.FC<DiscoButtonProps & ButtonProps> = props => {
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [hoverAngle, setHoverAngle] = useState(0);
  const handleSubmitMouseMove = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!e.target) return;

    const pos = e.currentTarget.getClientRects()[0];
    const mouseX = e.clientX - pos.x;
    const mouseY = e.clientY - pos.y;
    const xDiff = pos.width - mouseX;
    const yDiff = pos.height - mouseY;

    setHoverAngle((xDiff / pos.width + yDiff / pos.height) * 120);
    setHoverPosition({
      x: mouseX,
      y: mouseY,
    });
  };
  return (
    <DiscoButtonStyled
      data-testid="DiscoButton"
      bgAngle={hoverAngle}
      bgPosition={hoverPosition}
      onMouseMove={handleSubmitMouseMove}
      onMouseLeave={() => setHoverPosition({ x: 0, y: 0 })}
      {...props}
    />
  );
};

export default DiscoButton;
