import { Button } from "@netmedi/frontend-design-system";
import styled from "styled-components";

type GlobalLoginSubmitProps = {
  bgPosition?: { x: number; y: number };
  bgAngle: number;
};

export const DiscoButtonStyled = styled(Button).attrs<GlobalLoginSubmitProps>(
  ({ bgPosition, bgAngle }) => ({
    height: "200px",
    style: {
      background: bgPosition?.x
        ? `conic-gradient(
      from ${bgAngle ?? 0}deg at ${bgPosition?.x ?? 0}px ${bgPosition?.y ?? 0}px,
      #ff00cc,
      #c510d9,
      #6df8ff,
      #f5ff00,
      #f5ff8f,
      #6df8ff,
      #c510d9
    )`
        : undefined,
    },
  }),
)<GlobalLoginSubmitProps>`
  /* Rainbow styles */
  background: linear-gradient(
    90deg,
    #ff00cc,
    #c510d9,
    #e8b71d,
    #e3e81d,
    #aa11d5,
    #1ddde8,
    #40f7f7,
    #dd00f3,
    #dd00f3
  );

  /* Animate gradient */
  background-size: 600% 600%;
  animation: rainbow 30s linear infinite;
  border: none;
  color: white;
  font-weight: bold;
  padding: 1rem 2rem;
  border-radius: 5px;
  text-shadow: 0 0 3px rgba(0, 0, 0, 1);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  :hover {
    color: white;
    text-shadow: 0 0 3px rgba(0, 0, 0, 1);
    background: conic-gradient(
      from 0deg at 10% 50%,
      #ff00cc,
      #c510d9,
      #6df8ff,
      #f5ff00,
      #f5ff8f,
      #6df8ff,
      #c510d9
    );
    background-position: 100% 50%;
  }
`;
